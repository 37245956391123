import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

const LinkCLoud = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              redirect {
                redirectUrl
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: links } = data.allWpPost
      function reloadPage(link) {
        window.location.href = link
      }
      const linkOutput = links.map(link => {
        return (
          <a
            className="button is-small is-light"
            key={link.node.id}
            href={`/${link.node.slug}`}
            target={link.node.redirect.redirectUrl ? '_blank' : ''}
            onClick={
              link.node.redirect.redirectUrl
                ? () => reloadPage(link.node.acf.redirect_url)
                : null
            }
          >
            {link.node.title}
          </a>
        )
      })

      return (
        <div className="container">
          <nav className="linkCloud has-text-centered">{linkOutput}</nav>
        </div>
      )
    }}
  />
)

export default LinkCLoud
