import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import LinkCloud from '../components/LinkCloud/LinkCloud'

const ContactBedankt = ({ data }) => {
  const { title: siteTitle, siteUrl } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet>
        <title>{`Contact bedankt | ${siteTitle}`}</title>
        <link rel="canonical" href={`${siteUrl}/contact-bedankt/`} />
        <meta name="description" content="Contact bedankt" />
        <meta name="og:title" content={`Contact bedankt | ${siteTitle}`} />
        <meta name="og:description" content="Contact bedankt" />
      </Helmet>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-3">Bedankt voor uw bericht</h1>
                <h2> We contacteren u zo spoedig mogelijk.</h2>
                <div>
                  <Link to="/" className="is-block is-size-4 is-size-5-mobile">
                    Home
                  </Link>
                  <Link
                    to="/contact"
                    className="is-block is-size-4 is-size-5-mobile"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LinkCloud />
    </Layout>
  )
}

export default ContactBedankt

export const queryContactBedankt = graphql`
  query contactBedanktQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
